<template>
  <transition name="fade-shrink" appear>
    <v-card
      v-if="!removed"
      style="border-radius: 0px; background-color: white; min-height: 100px;"
      width="100%"
      elevation="2"
    >
     <!-- Fix -->
      <!-- Product Card -->
      <div class="d-flex flex-column" style="height: 100%;">
        <div class="d-flex" style="overflow: hidden; position: relative">
          <div class="d-flex flex-column justify-center" style="height: 100px; position: relative;">
            <v-img
              style="
                height: 100px;
                min-width: 100px !important;
                object-fit: contain !important;
              "
              class="mr-3"
              lazy-src="@/assets/product-image-placeholder.jpg"
              max-width="130"
              :src="product.imagem"
              contain
            />
            <v-chip
              v-if="product.hasActivePromotion"
              class="promotion green lighten-1"
              color="green"
              x-small
            >
              PROMOÇÃO
            </v-chip>
          </div>
          <div
            v-if="product.free_rate_quantity"
            class="ribbon ribbon-top-left ribbon-blue"
          >
            <span>Frete grátis</span>
          </div>
          <div class="d-flex flex-column pt-2" style="width: 100%; height: 100%">
            <div style="line-height: 1rem; height: 50px">
              <span class="font-weight-medium product-name">{{
                product.name
              }}</span>
              <div
                class="my-1 tags"
                style="height: 45px; overflow-y: auto; font-size: 11px"
              >
                <span>{{ product.tags ? product.tags.join(", ") : "tag" }}</span>
              </div>
            </div>

            <div class="flex-grow-1 d-flex flex-column justify-end pr-md-2">
              <div
                class="d-flex flex-column flex-md-row justify-space-between overflow-hidden"
              >
                <div
                  v-if="
                    (product.promotions[0] ?? null) &&
                    product.promotions[0].compositions.length == 0
                  "
                >
                  <span style="font-size: 14px"
                    >De:
                    <s>{{
                      formatMoney(getProductPrice(product) * product.quantity)
                    }}</s></span
                  >
                  <v-chip
                    v-if="product.promotions[0].discount_type !== 'fixed'"
                    class="ml-1"
                    color="success"
                    x-small
                  >
                    -{{ product.promotions[0].discount_value / 100 }}%
                  </v-chip><br />
                  <span class="title primary--text"
                    >Por:
                    {{ formatMoney(product.totalPrice * product.quantity) }}</span
                  >
                </div>
                <div v-else>
                  <span class="title primary--text" style="font-size: 18px !important;">{{
                    formatMoney(product.totalPrice * product.quantity)
                  }}</span>
                </div>
                <v-card-actions class="pa-0">
                  <div class="d-flex align-center">
                    <div
                      class="d-flex align-center px-0"
                      style="border: 1px solid #ccc; border-radius: 25px"
                    >
                      <v-btn
                        x-small
                        outlined
                        plain
                        class="transparent"
                        style="padding: 0px !important; border-radius: 25px"
                        :color="product.quantity > 1 ? 'primary' : 'dark'"
                        @click="subQuantity"
                      >
                        <span v-if="product.quantity > 1">
                          <v-icon size="16">mdi-minus</v-icon>
                        </span>
                        <v-icon v-else size="16">mdi-delete</v-icon>
                      </v-btn>
                      <span
                        style="font-size: 15px; min-width: 40px"
                        class="gray--text text-center"
                      >
                        {{ product.quantity }}
                      </span>
                      <v-btn
                        x-small
                        :disabled="
                          product?.stock_logic &&
                          product.quantity >= product?.stock
                            ? true
                            : false
                        "
                        color="primary"
                        class="transparent"
                        style="padding: 0px !important; border-radius: 25px"
                        outlined
                        plain
                        @click="addQuantity"
                      >
                        <v-icon size="16">mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <!-- <v-btn
                        :color="product.quantity == 1 ? 'danger' : 'primary'"
                        class="quantity-btn left"
                        @click="subQuantity()"
                      >
                        <span v-if="product.quantity > 1">-</span>
                        <v-icon x-small v-else>mdi-delete</v-icon>
                      </v-btn>
                      <span class="quantity-box">
                        <span v-if="product.measure_unit === 'un'">
                          {{ (product.quantity + '').padStart(2, '0') }}
                        </span>
                        <span v-if="product.measure_unit === 'kg'">{{ product.quantity + '' }}</span>
                        <span v-if="product.measure_unit === 'kg'">kg</span>
                      </span>
                      <v-btn
                        color="primary"
                        :disabled="product.stock_logic && quantity >= product.stock ? true : false"
                        class="quantity-btn right"
                        @click="addQuantity()"
                      >
                      <span>+</span>
                      </v-btn> -->
                  </div>
                </v-card-actions>
              </div>
            </div>
            <v-icon
              class="trash"
              color="danger"
              @click="$emit('deleted', product.id)"
              x-small
              >mdi-delete</v-icon
            >
          </div>
        </div>
        <bag-product-detail :product="product" />
      </div>
    </v-card>
  </transition>
</template>

<script>
import BagProductDetail from "@/components/cards/BagProductDetail.vue";

export default {
  name: "ProductCard",
  props: ["product"],
  data() {
    return {
      removed: false,
    };
  },
  computed: {},
  components: {
    BagProductDetail,
  },
  methods: {
    goToProduct(productId) {
      this.$router.replace(`produto/${productId}`);
    },
    subQuantity() {
      if (this.product.quantity <= 1) {
        this.removeProduct();
        return;
      }

      if (this.product.measure_unit === "kg") {
        this.product.quantity = parseFloat(this.product.quantity) - 0.1;
        this.product.quantity = this.product.quantity.toFixed(2);
      } else {
        this.product.quantity--;
      }
    },
    addQuantity() {
      if (this.product.measure_unit === "kg") {
        this.product.quantity = parseFloat(this.product.quantity) + 0.1;
        this.product.quantity = this.product.quantity.toFixed(2);
      } else {
        this.product.quantity++;
      }

      if (
        this.product.stock_logic &&
        this.product.quantity >= this.product.stock
      ) {
        this.$toast.info(
          "Quantidade máxima de estoque atingida para o produto " +
            this.product.name
        );
      }
    },
    removeProduct() {
      this.removed = true;
      setTimeout(() => {
        this.$emit("deleted", this.product.id);
      }, 300); // Ajuste este valor para corresponder à duração da sua animação
    },
  },
};
</script>

<style scoped>
.quantity-box {
  width: 100%;
  font-size: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 21px;
  padding: 5px;
  margin-top: 1px;
  padding-top: 2px;
}

.product-name {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tags {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trash {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px !important;
}

@media (min-width: 768px) {
  .trash {
    top: 20px;
    height: 0px;
    bottom: 0px;
    right: 5px;
  }
}

.quantity-btn {
  border: 0px;
  color: #fff;
  min-width: 0 !important;
  padding: 0 !important;
  width: 25px !important;
  font-size: 10px;
  height: 20px !important;
  min-width: none !important;
}

.quantity-btn.left {
  border-radius: 100px 0px 0 100px;
}

.quantity-btn.right {
  border-radius: 0px 100px 100px 0px;
}

.promotion {
  color: #fff !important;
	position: absolute;
  border-radius: 0px 20px 0px 0px !important;
  left: 0;
	bottom: 0px !important;
}

.v-card {
  background-color: white !important;
}

.fade-shrink-enter-active,
.fade-shrink-leave-active {
  transition: all 0.3s ease;
}

.fade-shrink-enter,
.fade-shrink-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
